<template>
  <a-card class="page-container">
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row :gutter="60">
        <a-col :span="8">
          <a-form-model-item label="品牌">
            <a-select v-model="form.principal_id" placeholder="请选择品牌" style="width: 100%">
              <a-select-option v-for="item in principalsList" :key="item.id">
                {{ item.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="活动名称">
            <a-input v-model="form.activity_name" placeholder="请输入" allow-clear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="handleSearch">查询</a-button>
              <a-button @click="handleReset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <div slot="action" slot-scope="text, record">
        <a @click="jumpDetailPage(record)">查看详情</a>
      </div>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      form: {},
      principalsList: [],
      loading: false,
      columns: [
        {
          align: 'center',
          title: '活动名称',
          dataIndex: 'activity_name',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '品牌',
          dataIndex: 'principal_name',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '统计范围',
          customRender(text, row) {
            const typeList = [];
            if (row.is_live === 1) {
              typeList.push('直播');
            }
            if (row.is_video === 1) {
              typeList.push('视频');
            }
            if (row.is_note === 1) {
              typeList.push('笔记');
            }
            return typeList.length > 0 ? typeList.join('，') : '-';
          },
        },
        {
          align: 'center',
          title: '活动平台',
          dataIndex: 'platform',
          customRender(text, row) {
            let arr = [];
            let content = '';
            try {
              arr = row.platform.split(',');
            } catch {
              arr = [row.platform];
            }

            arr.map((item, index) => {
              let symbols = index == arr.length - 1 ? '' : '，';
              if (item == 1) {
                content += '抖音' + symbols;
              } else if (item == 2) {
                content += '快手' + symbols;
              } else if (item == 3) {
                content += '懂车帝' + symbols;
              } else if (item == 4) {
                content += '视频号' + symbols;
              } else if (item == 5) {
                content += '小红书' + symbols;
              }
            });
            return content;
          },
        },
        {
          align: 'center',
          title: '关联模板(已确认)',
          dataIndex: 'template_count',
          customRender(text) {
            return (text ?? '') !== '' ? text : '-';
          },
        },
        {
          align: 'center',
          title: '开始时间',
          dataIndex: 'start_time',
          customRender(text) {
            return dayjs(text).format('YYYY-MM-DD') || '-';
          },
        },
        {
          align: 'center',
          title: '结束时间',
          dataIndex: 'end_time',
          customRender(text) {
            return dayjs(text).format('YYYY-MM-DD') || '-';
          },
        },
        {
          align: 'center',
          title: '更新时间',
          dataIndex: 'mtime',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '操作',
          key: 'action',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getPrincipals();
    this.getActivityList();
  },
  methods: {
    getPrincipals() {
      this.$api.core.TrainingList.getPrincipals().then((res) => {
        this.principalsList = res;
      });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getActivityList();
    },
    handleReset() {
      this.form = {};
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getActivityList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getActivityList();
    },
    getActivityList() {
      this.loading = true;
      let params = Object.assign(this.form, {
        page_num: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.$api.jjtActives.getActivityList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取详情失败${res}`);
        }
      });
    },
    jumpDetailPage(record) {
      this.$router.push({
        name: 'jjtActives:detail',
        params: {
          groupId: record.id,
        },
        query: {
          principalId: record.principal_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;

  .data-table {
    margin: 10px 0 0;
  }
}
</style>
